import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import { Form, InputGroup } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import { debounce } from "lodash";

export const PpobTransactionsReport = () => {
  const [dateStart, setDateStart] = useState(
    getFormattedDate(new Date(new Date().setDate(new Date().getDate() - 30)))
  );
  const [dateEnd, setDateEnd] = useState(getFormattedDate(new Date()));
  const [transactionData, setTransactionData] = useState([]);
  const [popularProductData, setPopularProductData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [allData, setAllData] = useState([]);
  const [dailyActivityData, setDailyActivityData] = useState([]);

  const authToken = useSelector((state) => state.auth.authToken);

  // Perbarui cara mendapatkan business_id
  const [businessId, setBusinessId] = useState(null);
  const [error, setError] = useState(null);

  // Fungsi untuk mengambil business_id
  const fetchBusinessId = useCallback(async () => {
    try {
      const userInfo = JSON.parse(localStorage.getItem("user_info"));
      if (userInfo && userInfo.business_id) {
        setBusinessId(userInfo.business_id);
      } else {
        // Jika tidak ada di localStorage, ambil dari API
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/business-info`, {
          headers: { Authorization: `Bearer ${authToken}` }
        });
        setBusinessId(data.data.id);
        // Simpan ke localStorage untuk penggunaan selanjutnya
        localStorage.setItem("user_info", JSON.stringify({ ...userInfo, business_id: data.data.id }));
      }
    } catch (error) {
      console.error("Error fetching business ID:", error);
      setError("Gagal mengambil informasi bisnis. Silakan coba lagi.");
    }
  }, [authToken]);

  useEffect(() => {
    fetchBusinessId();
  }, [fetchBusinessId]);

  const fetchData = useCallback(async () => {
    if (!businessId) return; // Jangan fetch jika businessId belum ada
    setLoading(true);
    setError(null);
    try {
      const formattedDateStart = new Date(dateStart)
        .toISOString()
        .split("T")[0];
      const formattedDateEnd = new Date(dateEnd).toISOString().split("T")[0];

      const response = await axios.get(
        `https://api.beetpos.com/api/v1/kios-saldo-history/merchant`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          params: {
            business_id: businessId,
            page: currentPage,
            per_page: 10,
            type: "purchase",
            date_start: formattedDateStart,
            date_end: formattedDateEnd
          }
        }
      );

      console.log("API Response:", response.data);

      if (response.data.statusCode === 200) {
        const responseData = response.data.data;
        console.log("Received data:", responseData);

        // Periksa apakah business_id dari respons cocok dengan yang kita miliki
        if (responseData.length > 0 && responseData[0].Business.id === businessId) {
          setAllData(responseData);
          filterData(responseData);
        } else {
          setError("Data tidak tersedia untuk bisnis ini.");
          setAllData([]);
          setFilteredData([]);
        }
      } else {
        setError("Terjadi kesalahan saat mengambil data.");
        setAllData([]);
        setFilteredData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Gagal mengambil data. Silakan coba lagi.");
      setAllData([]);
      setFilteredData([]);
    } finally {
      setLoading(false);
    }
  }, [authToken, businessId, currentPage, dateStart, dateEnd]);

  const filterData = useCallback(
    (data) => {
      let filteredResponseData = data;

      // Filter berdasarkan kode top up
      if (searchTerm) {
        filteredResponseData = filteredResponseData.filter((item) => {
          const code = item.Kios_Topup_Saldo.code.toLowerCase();
          const search = searchTerm.toLowerCase();
          return code.includes(search);
        });
      }

      console.log("Filtered data:", filteredResponseData);

      setFilteredData(filteredResponseData);
      calculateChartData(filteredResponseData);
      setTotalPages(Math.ceil(filteredResponseData.length / 10));
    },
    [searchTerm]
  );

  const calculateChartData = useCallback((data) => {
    // Aktivitas Transaksi Daily (Total biaya merchant per hari)
    const dailyTotals = data.reduce((acc, item) => {
      const date = new Date(
        item.Kios_Topup_Saldo.code.split("-")[2] * 1000
      ).toLocaleDateString("id-ID");
      if (!acc[date]) {
        acc[date] = 0;
      }
      acc[date] += item.merchant_cost;
      return acc;
    }, {});

    const dailyData = Object.entries(dailyTotals).map(([date, total]) => ({
      date,
      total
    }));

    dailyData.sort((a, b) => new Date(a.date) - new Date(b.date));
    setDailyActivityData(dailyData);

    // Aktivitas Produk Populer (Total biaya merchant per bisnis)
    const productTotals = data.reduce((acc, item) => {
      if (!acc[item.Business.name]) {
        acc[item.Business.name] = 0;
      }
      acc[item.Business.name] += item.merchant_cost;
      return acc;
    }, {});

    const popularData = Object.entries(productTotals).map(([name, value]) => ({
      name,
      value
    }));

    popularData.sort((a, b) => b.value - a.value);
    setPopularProductData(popularData);
  }, []);

  useEffect(() => {
    if (businessId) {
      fetchData();
    }
  }, [fetchData, businessId]);

  useEffect(() => {
    filterData(allData);
  }, [filterData, allData]);

  const debouncedFetchData = useMemo(() => debounce(fetchData, 300), [
    fetchData
  ]);

  useEffect(() => {
    debouncedFetchData();
    return () => debouncedFetchData.cancel();
  }, [debouncedFetchData]);

  const containerStyle = {
    padding: "20px",
    backgroundColor: "#f5f5f5",
    fontFamily: "Arial, sans-serif"
  };

  const titleStyle = {
    fontSize: "24px",
    marginBottom: "20px"
  };

  const chartsContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px"
  };

  const chartCardStyle = {
    width: "48%",
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "15px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
  };

  const chartHeaderStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "15px"
  };

  const chartTitleStyle = {
    fontSize: "18px",
    margin: "0"
  };

  const datePickerContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px"
  };

  function getFormattedDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === "dateStart") {
      setDateStart(value);
    } else if (name === "dateEnd") {
      setDateEnd(value);
    }
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    backgroundColor: "white",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
  };

  const thStyle = {
    padding: "12px",
    textAlign: "center",
    backgroundColor: "#1a237e",
    color: "white",
    borderBottom: "1px solid #eee"
  };

  const tdStyle = {
    padding: "12px",
    textAlign: "center",
    borderBottom: "1px solid #eee"
  };

  const renderImage = (imagePath) => {
    if (!imagePath) return <span>Tidak ada Gambar</span>;

    const fullImageUrl = `${process.env.REACT_APP_API_URL}${imagePath}`;
    return (
      <img
        src={fullImageUrl}
        alt="Bukti Transfer"
        style={{ width: "50px", height: "50px", cursor: "pointer" }}
        onClick={() => setSelectedImage(fullImageUrl)}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = "/path/to/placeholder-image.jpg"; // Ganti dengan path placeholder image Anda
        }}
      />
    );
  };

  const ImageModal = ({ imageUrl, onClose }) => {
    if (!imageUrl) return null;

    return (
      <div
        className="modal"
        style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header" style={{ position: "relative" }}>
              <h5 className="modal-title">Gambar Bukti Transfer</h5>
              <button
                type="button"
                onClick={onClose}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                  background: "none",
                  border: "none",
                  fontSize: "24px",
                  fontWeight: "bold",
                  cursor: "pointer",
                  color: "#000",
                  padding: "0",
                  lineHeight: "1"
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <img
                src={imageUrl}
                alt="Bukti Transfer"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>Report transaction PPOB</h1>

      <div style={datePickerContainerStyle}>
        <div className="col-lg-4 mb-lg-0 mb-6">
          <Form.Group>
            <Form.Label>Tanggal Mulai:</Form.Label>
            <Form.Control
              type="date"
              name="dateStart"
              value={dateStart}
              onChange={handleDateChange}
            />
          </Form.Group>
        </div>
        <div className="col-lg-4 mb-lg-0 mb-6">
          <Form.Group>
            <Form.Label>Tanggal Akhir:</Form.Label>
            <Form.Control
              type="date"
              name="dateEnd"
              value={dateEnd}
              onChange={handleDateChange}
            />
          </Form.Group>
        </div>
        <div className="col-lg-4 mb-lg-0 mb-6">
          <Form.Group>
            <Form.Label>Cari Kode Top Up:</Form.Label>
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Masukkan kode top up"
                value={searchTerm}
                onChange={handleSearch}
              />
              <InputGroup.Text>
                <i className="fas fa-search"></i>
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </div>
      </div>

      {loading ? (
        <p>Memuat data...</p>
      ) : error ? (
        <p style={{ color: 'red' }}>{error}</p>
      ) : filteredData.length > 0 ? (
        <>
          <div style={chartsContainerStyle}>
            <div style={chartCardStyle}>
              <div style={chartHeaderStyle}>
                <h2 style={chartTitleStyle}>Total Biaya Merchant Harian</h2>
              </div>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={dailyActivityData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="total"
                    fill="#8884d8"
                    name="Total Biaya Merchant"
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>

            <div style={chartCardStyle}>
              <div style={chartHeaderStyle}>
                <h2 style={chartTitleStyle}>Total Biaya Merchant per Bisnis</h2>
              </div>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={popularProductData}
                  layout="vertical"
                  barSize={20}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" />
                  <YAxis dataKey="name" type="category" />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="value"
                    fill="#8884d8"
                    name="Total Biaya Merchant"
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={thStyle}>#</th>
                <th style={thStyle}>Nama Bisnis</th>
                <th style={thStyle}>Kode Top Up</th>
                <th style={thStyle}>Jumlah</th>
                <th style={thStyle}>Biaya Merchant</th>
                <th style={thStyle}>Saldo Baru</th>
                <th style={thStyle}>Tanggal</th>
                <th style={thStyle}>Gambar</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={index}>
                  <td style={tdStyle}>{index + 1}</td>
                  <td style={tdStyle}>{item.Business.name}</td>
                  <td style={tdStyle}>{item.Kios_Topup_Saldo.code}</td>
                  <td style={tdStyle}>
                    {item.Kios_Topup_Saldo.amount.toLocaleString()}
                  </td>
                  <td style={tdStyle}>{item.merchant_cost.toLocaleString()}</td>
                  <td style={tdStyle}>{item.new_value.toLocaleString()}</td>
                  <td style={tdStyle}>
                    {new Date(
                      item.Kios_Topup_Saldo.code.split("-")[2] * 1000
                    ).toLocaleDateString("id-ID")}
                  </td>
                  <td style={tdStyle}>
                    {renderImage(item.Kios_Topup_Saldo.image)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <ImageModal
            imageUrl={selectedImage}
            onClose={() => setSelectedImage(null)}
          />

          <div className="d-flex justify-content-between align-items-center mt-4">
            <button
              className="btn btn-secondary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="btn btn-secondary"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </>
      ) : (
        <p>
          Tidak ada data yang sesuai dengan filter yang dipilih. (Search Term:{" "}
          {searchTerm})
        </p>
      )}
    </div>
  );
};

export default PpobTransactionsReport;
