import React from "react";
import axios from "axios";
import { Table } from "react-bootstrap";
import rupiahFormat from "rupiah-format";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import "../style.css";
import NumberFormat from "react-number-format";
import {
  Switch,
  FormGroup,
  FormControl,
  FormControlLabel,
  Paper
} from "@material-ui/core";

import {
  Dropdown,
  Row,
  Col,
  DropdownButton,
  Form,
  InputGroup
} from "react-bootstrap";

import { FeatureReport } from "./components/FeatureReport";
import Loader from "../../../Loader.jsx";

export const SalesSummaryTab = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [dataRevenue, setDataRevenue] = React.useState({});

  const [mdr, setMdr] = React.useState("");
  const [allTransactions, setAllTransactions] = React.useState([]);
  const [allSales, setAllSales] = React.useState([]);
  const [currency, setCurrency] = React.useState("");

  const [refresh, setRefresh] = React.useState(0);
  const handleRefresh = () => setRefresh((state) => state + 1);

  const [selectedOutlet, setSelectedOutlet] = React.useState({
    id: "",
    name: "All Outlet"
  });
  const [startDate, setStartDate] = React.useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(dayjs().format("YYYY-MM-DD"));
  const [endDateFilename, setEndDateFilename] = React.useState("");
  const [startTime, setStartTime] = React.useState(new Date());
  const [endTime, setEndTime] = React.useState(new Date());
  const [tabData, setTabData] = React.useState({
    no: 1,
    table: "table-summary",
    filename: `transaksi-penjualan-produk_${startDate}-${endDateFilename}`
  });
  const [status, setStatus] = React.useState("");

  const [reports, setReports] = React.useState([
    {
      product_name: "",
      addons_name: "",
      category_name: "",
      sku: "",
      totalItems: 0,
      grossSales: 0,
      discountSales: 0,
      totalSales: 0
    }
  ]);
  const { t } = useTranslation();

  const handleCurrency = async () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const userInfo = JSON.parse(localStorage.getItem("user_info"));

    const { data } = await axios.get(
      `${API_URL}/api/v1/business/${userInfo.business_id}`
    );

    // console.log("currency nya brpw", data.data.Currency.name)
    setCurrency(data.data.Currency.name);
  };

  React.useEffect(() => {
    handleCurrency();
  }, []);

  // const getTransactions = async (id, start_range, end_range) => {
  //   const API_URL = process.env.REACT_APP_API_URL;
  //   const outlet_id = id ? `?outlet_id=${id}&` : "?";

  //   if (start_range === end_range) {
  //     end_range = dayjs(end_range)
  //       .add(1, "day")
  //       .format("YYYY-MM-DD");
  //   }

  //   if (new Date(start_range) > new Date(end_range)) {
  //     start_range = dayjs(start_range)
  //       .subtract(1, "day")
  //       .format("YYYY-MM-DD");
  //     end_range = dayjs(end_range)
  //       .add(1, "day")
  //       .format("YYYY-MM-DD");
  //   }

  //   try {

  //     console.log("start_range", start_range)
  //     console.log("end_range", end_range)
  //     const { data } = await axios.get(
  //       `${API_URL}api/v1/transaction/mdr-lite${outlet_id}date_start=${start_range}&date_end=${end_range}&per_page=999999999`
  //     );

  //     // console.log("all transaction", data.data)
  //     setAllTransactions(data.data);
  //     setAllSales(data.data)
  //   } catch (err) {
  //     if (err.response?.status === 404) {
  //       setAllTransactions([]);
  //     }
  //     setAllSales([])
  //     console.log(err);
  //   } finally {
  //     setIsLoading(false);
  //   }

  //   const completedTransactions = allSales.filter(
  //     (item) => item.Payment?.status === "done"
  //   );
  //   const transItems = [];
  //   const prodIdList = [];
  //   for (const item of completedTransactions) {
  //     item.Transaction_Items.forEach((val) => {
  //       transItems.push(val);
  //       if (!prodIdList.includes(val.product_id))
  //         prodIdList.push(val.product_id);
  //     });
  //   }

  //   // sort transItems by product
  //   transItems.sort((a, b) => {
  //     if (a.product_id > b.product_id) return 1;
  //     if (b.product_id > a.product_id) return -1;
  //   });
  //   prodIdList.sort();

  //   // get gross sales of each product
  //   let allItems2 = [];
  //   prodIdList.forEach((val) => {
  //     const temp = transItems.filter((item) => item.product_id === val);
  //     const grossSales = temp.reduce(
  //       (init, curr) => (init += curr.price_total + curr.price_discount),
  //       0
  //     );

  //     const totalSales = temp.reduce(
  //       (init, curr) => (init += curr.price_total),
  //       0
  //     );
  //     const quantity = temp.reduce((init, curr) => (init += curr.quantity), 0);
  //     let joinedAddons = "";
  //     for (const item of temp) {
  //       const currAddons = item.Transaction_Item_Addons.map(
  //         (addons) => addons.Addon?.name
  //       ).filter((val) => val);

  //       joinedAddons = currAddons.length ? currAddons.join(",") : "";
  //     }

  //     allItems2.push({
  //       product_name: temp[0].Product.name,
  //       addons_name: joinedAddons,
  //       category_name: temp[0].Product.Product_Category?.name,
  //       sku: temp[0].Product?.sku,
  //       totalItems: quantity,
  //       grossSales: grossSales,
  //       // discountSales: discountSales,
  //       totalSales: totalSales
  //     });
  //   });

  //   const allProductNames = allItems2.flat(1).reduce((init, curr) => {
  //     init[`${curr.product_name}-${curr.addons_name}`] = curr?.category_name;
  //     return init;
  //   }, {});

  //   const compileReports = Object.keys(allProductNames).map((item) => {
  //     const name = item.split("-")[0];
  //     const addons = item.split("-")[1];
  //     const category = allProductNames[item];

  //     const sku = allItems2
  //       .flat(1)
  //       .filter(
  //         (val) => val.product_name === name && val.addons_name === addons
  //       )
  //       .reduce((init, curr) => (init = curr?.sku), "");

  //     const totalItems = allItems2
  //       .flat(1)
  //       .filter(
  //         (val) => val.product_name === name && val.addons_name === addons
  //       )
  //       .reduce((init, curr) => (init += curr.totalItems), 0);

  //     const grossSales = allItems2
  //       .flat(1)
  //       .filter(
  //         (val) => val.product_name === name && val.addons_name === addons
  //       )
  //       .reduce((init, curr) => (init += curr.grossSales), 0);

  //     const totalSales = allItems2
  //       .flat(1)
  //       .filter(
  //         (val) => val.product_name === name && val.addons_name === addons
  //       )
  //       .reduce((init, curr) => (init += curr.totalSales), 0);
  //     return {
  //       product_name: name,
  //       addons_name: addons,
  //       category_name: category,
  //       sku,
  //       totalItems,
  //       grossSales,
  //       // discountSales,
  //       totalSales
  //     };
  //   });
  //   setReports(compileReports);
  // };

  const getTransactions = async (id, start_range, end_range) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const outlet_id = id ? `?outlet_id=${id}&` : "?";

    if (start_range === end_range) {
      end_range = dayjs(end_range)
        .add(1, "day")
        .format("YYYY-MM-DD");
    }

    if (new Date(start_range) > new Date(end_range)) {
      start_range = dayjs(start_range)
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      end_range = dayjs(end_range)
        .add(1, "day")
        .format("YYYY-MM-DD");
    }

    try {
      setIsLoading(true);

      // const { data } = await axios.get(
      //   `${API_URL}api/v1/transaction/mdr${outlet_id}date_start=${start_range}&date_end=${end_range}`
      // );

      const { data } = await axios.get(
        `${API_URL}api/v1/transaction/mdr-lite${outlet_id}date_start=${start_range}&date_end=${end_range}&per_page=99999`
      );

      // console.log("all transaction", data.data);
      setAllTransactions(data.data);
      setAllSales(data.data);

      const completedTransactions = data.data.filter(
        (item) => item.Payment?.status === "done"
      );

      // console.log("ini completed transaction", completedTransactions);

      const transItems = [];
      const prodIdList = [];
      for (const item of completedTransactions) {
        item.Transaction_Items.forEach((val) => {
          transItems.push(val);
          if (!prodIdList.includes(val.product_id))
            prodIdList.push(val.product_id);
        });
      }

      // console.log("ini transItems", transItems);
      // console.log("ini prodIdList", prodIdList);

      transItems.sort((a, b) => (a.product_id > b.product_id ? 1 : -1));
      prodIdList.sort();

      const allItems2 = prodIdList.map((val) => {
        const temp = transItems.filter((item) => item.product_id === val);
        // console.log(`ini temp for product_id ${val}:`, temp);
        if (temp.length === 0) return null; // Ensure temp is not empty
        const grossSales = temp.reduce(
          (init, curr) => init + curr.price_total + curr.price_discount,
          0
        );

        const totalSales = temp.reduce(
          (init, curr) => init + curr.price_total,
          0
        );

        const quantity = temp.reduce((init, curr) => init + curr.quantity, 0);

        const joinedAddons = temp
          .flatMap((item) =>
            item.Transaction_Item_Addons.map(
              (addons) => addons.Addon?.name
            ).filter((val) => val)
          )
          .join(",");

        return {
          product_name: temp[0].Product.name,
          addons_name: joinedAddons,
          category_name: temp[0].Product.Product_Category?.name,
          sku: temp[0].Product?.sku,
          totalItems: quantity,
          grossSales,
          totalSales
        };
      });

      // console.log("ini all items2", allItems2);

      const allProductNames = allItems2.reduce((init, curr) => {
        init[`${curr.product_name}---${curr.addons_name}`] =
          curr?.category_name;
        return init;
      }, {});

      // console.log("ini all product names", allProductNames);

      const compileReports = Object.keys(allProductNames).map((item) => {
        const [name, addons] = item.split("---");
        const category = allProductNames[item];

        const sku = allItems2.find(
          (val) => val.product_name === name && val.addons_name === addons
        )?.sku;

        const totalItems = allItems2
          .flat(1)
          .filter(
            (val) => val.product_name === name && val.addons_name === addons
          )
          .reduce((init, curr) => (init += curr.totalItems), 0);

        const grossSales = allItems2
          .flat(1)
          .filter(
            (val) => val.product_name === name && val.addons_name === addons
          )
          .reduce((init, curr) => (init += curr.grossSales), 0);

        const totalSales = allItems2
          .flat(1)
          .filter(
            (val) => val.product_name === name && val.addons_name === addons
          )
          .reduce((init, curr) => (init += curr.totalSales), 0);

        return {
          product_name: name,
          addons_name: addons,
          category_name: category,
          sku,
          totalItems,
          grossSales,
          totalSales
        };
      });

      setReports(compileReports);
    } catch (err) {
      if (err.response?.status === 404) {
        setAllTransactions([]);
      }
      setAllSales([]);
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const calculateRevenue = async () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const userInfo = JSON.parse(localStorage.getItem("user_info"));
    try {
      const { data } = await axios.get(
        `${API_URL}/api/v1/business-revenue-sharing/business-id/${userInfo.business_id}`
      );
      console.log("calculateRevenue", data.data);
      setDataRevenue(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      await getTransactions(selectedOutlet.id, startDate, endDate);
      calculateRevenue();
      setTabData({
        ...tabData,
        filename: `transaksi-penjualan-produk_${startDate}-${endDateFilename}`
      });
    };
    fetchData();
  }, [selectedOutlet, startDate, endDate, refresh, mdr, endDateFilename]);

  const summaryData = () => {
    const data = [
      {
        key: `${t("income")}`,
        value: 0
      },
      {
        key: `${t("grossSales")}`,
        value: 0
      },
      {
        key: `${t("discount")}`,
        value: 0
      },
      {
        key: `${t("void")}`,
        value: 0
      },
      {
        key: `${t("nettSales")}`,
        value: 0
      },
      {
        key: `${t("services")}`,
        value: 0
      },
      {
        key: `${t("tax")}`,
        value: 0
      },
      {
        key: `${t("rounding")}`,
        value: 0
      },
      {
        key: `${t("totalCollected")}`,
        value: 0
      }
    ];

    const completedTransactions = allTransactions.filter(
      (item) =>
        item.Payment?.status === "done" || item.Payment?.status === "refund"
    );
    const doneTransactions = allTransactions.filter(
      (item) => item.Payment?.status === "done"
    );
    const voidTransactions = allTransactions.filter(
      (item) => item.Payment?.status === "refund"
    );

    const income = completedTransactions.reduce(
      (init, curr) => (init += curr.Payment?.payment_total),
      0
    );

    // discount
    const discount = completedTransactions.reduce(
      (init, curr) => (init += curr.Payment?.payment_discount),
      0
    );

    // income sales
    const incomeSales = income;
    data[0].value = incomeSales + discount;

    // gross sales dikurangi hpp
    const grossSales = income;
    data[1].value = grossSales + discount;

    // discount
    data[2].value = discount;

    // refund / void
    const voidSales = voidTransactions.reduce(
      (init, curr) => (init += curr.Payment.payment_total),
      0
    );
    data[3].value = voidSales;

    // bonus or services
    const bonus = completedTransactions.reduce(
      (init, curr) => (init += curr.Payment?.payment_service),
      0
    );

    // nett sales
    const nettSales = grossSales - voidSales - bonus;
    data[4].value = nettSales;

    // bonus or services
    data[5].value = bonus;

    // tax
    const taxSales = doneTransactions.reduce(
      (init, curr) => (init += curr.Payment.payment_tax),
      0
    );
    data[6].value = taxSales;

    // rounding
    const roundingSales = 0;
    data[7].value = roundingSales;

    // total
    //const totalCollected = nettSales - bonus - taxSales + roundingSales;
    const totalCollected = nettSales + bonus - taxSales + roundingSales;
    data[8].value = totalCollected;

    // Calculate Revenue Sharing
    const percentage_manager = dataRevenue.manager_percent_share / 100;
    const temp_kali_manager = totalCollected * percentage_manager;

    const percentage_business = dataRevenue.business_percent_share / 100;
    const temp_kali_business = totalCollected * percentage_business;

    const result_revenue_manager = temp_kali_manager;
    const result_revenue_business = temp_kali_business;
    // End Calculate Revenue Sharing

    return {
      data,
      resultRevenueBusiness: {
        result_revenue_manager:
          result_revenue_manager > 0 ? result_revenue_manager : "",
        result_revenue_business:
          result_revenue_business > 0 ? result_revenue_business : "-"
      }
    };
  };

  const sumReports = (data, key) => {
    return data.reduce((init, curr) => (init += curr[key]), 0);
  };

  let totalDiscount = 0;
  let totalService = 0;
  if (allTransactions.length) {
    const temp = allTransactions.filter(
      (item) => item.Payment?.status === "done"
    );
    // temp.forEach((item) => {
    //   totalDiscount += item.Payment.payment_discount;
    // });
    totalDiscount = temp.reduce(
      (init, curr) => (init += curr.Payment?.payment_discount),
      0
    );
    totalService = temp.reduce(
      (init, curr) => (init += curr.Payment?.payment_service),
      0
    );
  }

  const completedTransactions = allTransactions.filter(
    (item) =>
      item.Payment?.status === "done" || item.Payment?.status === "refund"
  );
  const doneTransactions = allTransactions.filter(
    (item) => item.Payment?.status === "done"
  );
  const voidTransactions = allTransactions.filter(
    (item) => item.Payment?.status === "refund"
  );

  const income = completedTransactions.reduce(
    (init, curr) => (init += curr.Payment?.payment_total),
    0
  );

  const grossSales = income;

  const voidSales = voidTransactions.reduce(
    (init, curr) => (init += curr.Payment.payment_total),
    0
  );

  const bonus = completedTransactions.reduce(
    (init, curr) => (init += curr.Payment?.payment_service),
    0
  );

  const taxSales = doneTransactions.reduce(
    (init, curr) => (init += curr.Payment.payment_tax),
    0
  );

  const nettSales = grossSales - voidSales - bonus;

  const roundingSales = 0;

  const totalCollected = nettSales + bonus - taxSales + roundingSales;

  // const grandTotal =
  //   sumReports(reports, "totalSales") - totalDiscount + totalService;
  const grandTotal = totalCollected;

  const handleStartDate = (date) => setStartDate(date);
  const handleEndDate = (date) => setEndDate(date);
  const handleEndDateFilename = (date) => setEndDateFilename(date);
  const handleSelectedOutlet = (outlet) => setSelectedOutlet(outlet);
  const handleSelectStatus = (status) => setStatus(status.target.value);
  const handleTimeStart = (time) => setStartTime(time);
  const handleTimeEnd = (time) => setEndTime(time);
  const handleMdr = (params) => setMdr(params);

  return (
    <>
      <Row>
        <Col>
          <Paper elevation={2} style={{ padding: "1rem", height: "100%" }}>
            <FeatureReport
              handleStartDate={handleStartDate}
              handleMdr={handleMdr}
              handleEndDate={handleEndDate}
              tabData={tabData}
              handleEndDateFilename={handleEndDateFilename}
              handleSelectedOutlet={handleSelectedOutlet}
              titleReport="reportSalesSummary"
              handleSelectStatus={handleSelectStatus}
              handleTimeStart={handleTimeStart}
              handleTimeEnd={handleTimeEnd}
              stateShowMdr={true}
              warning={true}
            />

            {isLoading ? ( // Conditional rendering based on loading state
              <Loader />
            ) : (
              <>
                {/* for excel */}
                <div style={{ display: "none" }}>
                  <table id="table-summary">
                    <thead>
                      <tr>
                        <th>{t("productSalesReport")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr></tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th>{t("outlet")}</th>
                        <td>{selectedOutlet?.name}</td>
                      </tr>
                    </thead>
                    <thead>
                      <tr>
                        <th>{t("date")}</th>
                        <td>{`${startDate} - ${endDateFilename}`}</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr></tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th>{t("productName")}</th>
                        <th>{t("category")}</th>
                        <th>{t("sku")}</th>
                        <th>{t("sold")}</th>
                        <th>{t("total")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reports
                        .filter((item) => item.totalItems > 0)
                        .map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.product_name}</td>
                              <td>{item.category_name}</td>
                              <td>{item.sku}</td>
                              <td>{item.totalItems}</td>
                              <td>{item.totalSales}</td>
                            </tr>
                          );
                        })}
                      <tr>
                        <th>{t("subtotal")}</th>
                        <th></th>
                        <th></th>
                        <th>{sumReports(reports, "totalItems")} </th>
                        <th>{sumReports(reports, "totalSales")} </th>
                      </tr>
                      <tr>
                        <th>{t("discountGiven")}</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>{totalDiscount}</th>
                      </tr>
                      <tr>
                        <th>{t("service")}</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>{totalService}</th>
                      </tr>
                      <tr>
                        <th>{t("grandTotal")}</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>{grandTotal}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* end for excel */}

                <Table striped>
                  <tbody>
                    {summaryData().data.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td></td>
                          <td>{item.key}</td>
                          <td>
                            <NumberFormat
                              value={item.value}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={currency}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td className="text-danger">
                        Profit Sharing ({dataRevenue.manager_percent_share}%)
                      </td>
                      <td className="text-danger">
                        -{" "}
                        <NumberFormat
                          value={
                            summaryData().resultRevenueBusiness
                              .result_revenue_manager
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rp."}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td className="text-success">
                        Net ({dataRevenue.business_percent_share}%)
                      </td>
                      <td className="text-success">
                        <NumberFormat
                          value={
                            summaryData().resultRevenueBusiness
                              .result_revenue_business
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"Rp."}
                        />
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </>
            )}
          </Paper>
        </Col>
      </Row>
    </>
  );
};
