import React, { useEffect } from "react";

import { Button, Modal, Form, Spinner, Alert } from "react-bootstrap";

const EditPpobProductModal = ({
  state,
  closeModal,
  loading,
  alert,
  title,
  formikPpobProduct,
  inputRef,
  t
}) => {
  useEffect(() => {
    // Calculate and update price whenever related fields change
    const calculatePrice = () => {
      const base_price = Number(formikPpobProduct.values.base_price) || 0;
      const merchant_cost = Number(formikPpobProduct.values.merchant_cost) || 0;
      const additional_cost =
        Number(formikPpobProduct.values.additional_cost) || 0;
      const admin_cost = Number(formikPpobProduct.values.admin_cost) || 0;

      const calculatedPrice =
        base_price + merchant_cost + additional_cost + admin_cost;
      formikPpobProduct.setFieldValue("price", calculatedPrice);
    };

    calculatePrice();
  }, [
    formikPpobProduct.values.base_price,
    formikPpobProduct.values.merchant_cost,
    formikPpobProduct.values.additional_cost,
    formikPpobProduct.values.admin_cost
  ]);

  // Focus on merchant cost input when modal opens
  useEffect(() => {
    if (state && inputRef.current) {
      inputRef.current.focus();
    }
  }, [state]);

  // Fungsi untuk memformat angka ke format rupiah
  const formatToRupiah = (number) => {
    return new Intl.NumberFormat('id-ID').format(number);
  };

  const labelStyle = {
    fontWeight: 'bold',
    color: '#6a7187',
    width: '150px', // Sesuaikan dengan kebutuhan
    display: 'inline-block'
  };

  const valueStyle = {
    display: 'inline-block',
    marginLeft: '10px'
  };

  const rowStyle = {
    margin: '15px 0',
    display: 'flex',
    alignItems: 'center'
  };

  return (
    <Modal show={state} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formikPpobProduct.handleSubmit}>
        <Modal.Body>
          {alert ? <Alert variant="danger">{alert}</Alert> : ""}

          <div style={rowStyle}>
            <span style={labelStyle}>{t("productName")}</span>
            <span style={valueStyle}>
              : {formikPpobProduct.values.name || "-"}
            </span>
          </div>

          <div style={rowStyle}>
            <span style={labelStyle}>{t("basePrice")}</span>
            <span style={valueStyle}>
              : Rp {formatToRupiah(formikPpobProduct.values.base_price) || "0"}
            </span>
          </div>

          <div style={rowStyle}>
            <span style={labelStyle}>{t("additionalCost")}</span>
            <span style={valueStyle}>
              : Rp {formatToRupiah(formikPpobProduct.values.additional_cost) || "0"}
            </span>
          </div>

          <div style={rowStyle}>
            <span style={labelStyle}>{t("merchantCost")}</span>
            <span style={valueStyle}>
              : <Form.Control
                  type="number"
                  placeholder="Ex. : 1000"
                  {...formikPpobProduct.getFieldProps("merchant_cost")}
                  ref={inputRef}
                  required
                  style={{ 
                    display: 'inline-block',
                    width: '200px',
                    marginLeft: '5px'
                  }}
                />
            </span>
          </div>

          <div style={rowStyle}>
            <span style={labelStyle}>{t("price")}</span>
            <span style={valueStyle}>
              : <Form.Control
                  type="number"
                  placeholder="Ex. : 20000"
                  {...formikPpobProduct.getFieldProps("price")}
                  readOnly
                  disabled
                  style={{ 
                    display: 'inline-block',
                    width: '200px',
                    marginLeft: '5px',
                    backgroundColor: '#f5f5f5'
                  }}
                />
            </span>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            {t("close")}
          </Button>
          <Button type="submit">
            {loading ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              `${t("saveChanges")}`
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditPpobProductModal;
