import React from "react";
import { Button, Modal, Spinner, Alert, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const EditTopupModal = ({ show, onHide, formik, loading, alert }) => {
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await formik.submitForm();
      console.log("Form values before submission:", formik.values);
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {t("editTopup")}
          {formik.values.code && (
            <div className="small text-muted">{formik.values.code}</div>
          )}
        </Modal.Title>
      </Modal.Header>

      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          {alert && <Alert variant="danger">{alert}</Alert>}

          <Form.Group>
            <Form.Label>{t("image")}:</Form.Label>
            <Form.Control
              type="file"
              name="image"
              onChange={(event) => {
                const file = event.currentTarget.files[0];
                if (file) {
                  formik.setFieldValue("image", file);
                }
              }}
            />

            {formik.values.image && (
              <img
                src={
                  typeof formik.values.image === "string"
                    ? formik.values.image
                    : URL.createObjectURL(formik.values.image)
                }
                alt="Preview"
                style={{ width: "100px", marginTop: "10px" }}
              />
            )}
          </Form.Group>

          <Form.Group>
            <Form.Label>{t("amount")}:</Form.Label>
            <Form.Control
              type="number"
              name="amount"
              value={formik.values.amount || ''}
              onChange={(e) => {
                const value = e.target.value;
                formik.setFieldValue("amount", value ? Number(value) : '');
              }}
              isInvalid={formik.touched.amount && formik.errors.amount}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.amount}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>{t("description")}:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="top_up_description"
              value={formik.values.top_up_description || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={
                formik.touched.top_up_description &&
                formik.errors.top_up_description
              }
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.top_up_description}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            {t("close")}
          </Button>
          <Button 
            variant="primary" 
            type="submit" 
            disabled={loading || formik.isSubmitting}
          >
            {loading || formik.isSubmitting ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              t("saveChanges")
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditTopupModal;
