// LoadingIndicator.js
import React from 'react'; 

const LoadingIndicator = () => {
  return (
    <div className="loading-container">
      <div className="spinner-1" />
    </div>
  );
};

export default LoadingIndicator;
