import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form,
  Row,
  Col,
  Alert,
  Spinner,
  Modal,
  Table
} from "react-bootstrap";
import { Paper } from "@material-ui/core";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../../Loader";

export function StaffLevelPage() {
  const [staffLevels, setStaffLevels] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [currentStaffLevel, setCurrentStaffLevel] = useState({});
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const API_URL = process.env.REACT_APP_API_URL;

  const userInfo = JSON.parse(localStorage.getItem("user_info"));
  const businessId = userInfo.business_id;
  const outletId = userInfo.outlet_id;

  useEffect(() => {
    fetchStaffLevels();
  }, []);

  const fetchStaffLevels = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/api/v1/staff-level?business_id=${businessId}&outlet_id=${outletId}`);
      setStaffLevels(response.data.data);
    } catch (error) {
      toast.error(t("failedToFetchStaffLevels"));
    }
    setLoading(false);
  };

  const handleShowModal = (mode, staffLevel = {}) => {
    setModalMode(mode);
    setCurrentStaffLevel(staffLevel);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentStaffLevel({});
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      if (modalMode === "add") {
        await axios.post(`${API_URL}/api/v1/staff-level`, {
          ...currentStaffLevel,
          business_id: businessId,
          outlet_id: outletId
        });
      } else {
        await axios.put(
          `${API_URL}/api/v1/staff-level/${currentStaffLevel.id}`,
          currentStaffLevel
        );
      }
      fetchStaffLevels();
      handleCloseModal();
      toast.success(t("staffLevelSavedSuccessfully"));
    } catch (error) {
      toast.error(t("failedToSaveStaffLevel"));
    }
    setLoading(false);
  };

  const handleDelete = async (id) => {
    if (window.confirm(t("deleteStaffLevel"))) {
      setLoading(true);
      try {
        await axios.delete(`${API_URL}/api/v1/staff-level/${id}`);
        fetchStaffLevels();
        toast.success(t("staffLevelDeletedSuccessfully"));
      } catch (error) {
        toast.error(t("failedToDeleteStaffLevel"));
      }
      setLoading(false);
    }
  };

  return (
    <Paper elevation={2} style={{ padding: "1rem", height: "100%" }}>
      <ToastContainer />
      <div className="headerPage">
        <div className="headerStart">
          <h3>{t("staffLevels")}</h3>
        </div>
        <div className="headerEnd">
          <Button variant="primary" onClick={() => handleShowModal("add")}>
            {t("addStaffLevel")}
          </Button>
        </div>
      </div>

      {loading ? (
        <Loader />
      ) : staffLevels.length === 0 ? (
        <Alert variant="warning">
          {t("thereAreNoStaffLevels")}, {t("pleaseAddOneFirst")}
        </Alert>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>{t("name")}</th>
              <th style={{ width: '200px', textAlign: 'center' }}>{t("actions")}</th>
            </tr>
          </thead>
          <tbody>
            {staffLevels.map((staffLevel) => (
              <tr key={staffLevel.id}>
                <td>{staffLevel.name}</td>
                <td>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={() => handleShowModal("edit", staffLevel)}
                    >
                      {t("edit")}
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(staffLevel.id)}
                    >
                      {t("delete")}
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalMode === "add" ? t("addStaffLevel") : t("editStaffLevel")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>{t("name")}</Form.Label>
              <Form.Control
                type="text"
                value={currentStaffLevel.name || ""}
                onChange={(e) =>
                  setCurrentStaffLevel({
                    ...currentStaffLevel,
                    name: e.target.value
                  })
                }
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                t("save")
              )}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Paper>
  );
}