import React, { useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import rupiahFormat from "rupiah-format";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import {
  Button,
  InputGroup,
  Form,
  Dropdown,
  Row,
  Col,
  ListGroup
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import "../style.css";
import { Paper } from "@material-ui/core";
import { FeatureReport } from "./components/FeatureReport";
import LoadingIndicator from "../../../Loader";
import ExportExcel from "react-html-table-to-excel";

export const CommissionStaff = () => {
  const [commission, setCommission] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [refresh, setRefresh] = React.useState(0);
  const [reports, setReports] = React.useState([]);
  const [servicerCommission, setServicerCommission] = React.useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [expandedData, setExpandedData] = useState({});

  // report state
  const [selectedOutlet, setSelectedOutlet] = React.useState({
    id: "433",
    name: "All Outlet"
  });
  const [startDate, setStartDate] = React.useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(dayjs().format("YYYY-MM-DD"));
  const [endDateFilename, setEndDateFilename] = React.useState("");
  const [startTime, setStartTime] = React.useState(new Date());
  const [endTime, setEndTime] = React.useState(new Date());
  const [tabData, setTabData] = React.useState({
    no: 10,
    table: "table-history-commission",
    filename: `riwayat-transaksi_${startDate}-${endDateFilename}`
  });
  const [status, setStatus] = React.useState("");
  const [mdr, setMdr] = React.useState("");
  const [isAnyRowExpanded, setIsAnyRowExpanded] = useState(false);

  const handleStartDate = (date) => setStartDate(date);
  const handleEndDate = (date) => setEndDate(date);
  const handleEndDateFilename = (date) => setEndDateFilename(date);
  const handleSelectedOutlet = (outlet) => setSelectedOutlet(outlet);
  const handleSelectStatus = (status) => setStatus(status.target.value);
  const handleTimeStart = (time) => setStartTime(time);
  const handleTimeEnd = (time) => setEndTime(time);
  const handleMdr = (params) => setMdr(params);

  const { t } = useTranslation();

  const ExportCommissionTable = ({ data, startDate, endDate, identifier }) => {
    return (
      <table id={identifier} style={{display: 'none'}}>
        <thead>
          <tr>
            <th>Transaction ID</th>
            <th>Receipt ID</th>
            <th>Product Name</th>
            <th>Price Product</th>
            <th>Komisi</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.transaction_id || "N/A"}</td>
              <td>{item.Transaction?.receipt_id || "N/A"}</td>
              <td>{item.Product?.name || "N/A"}</td>
              <td>{item.price_product || 0}</td>
              <td>{item.service_komisi || 0}</td>
              <td>{dayjs(item.createdAt).format("DD-MM-YYYY HH:mm:ss")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const fetchReportData = async () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const businessId = JSON.parse(localStorage.getItem("user_info"))
      .business_id;

    try {
      const { data } = await axios.get(
        `${API_URL}api/v1/transaction-test/report-komisi-detail`,
        {
          params: {
            business_id: businessId,
            outlet_id: selectedOutlet.id,
            date_start: startDate,
            date_end: endDate,
            page: 1,
            per_page: 9999
          }
        }
      );
      setReports(data.data);
      console.log("ini data report", data.data);
    } catch (error) {
      console.log("Error fetching report data:", error);
      setReports([]);
    }
  };

  // useEffect to show loading indicator in DataTable component
  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getCommissionData(selectedOutlet.id, status, startDate, endDate);
      // await reportComponent(commission, startDate, endDate);
      await fetchReportData(); // Fetch the report data here
      setLoading(false);
    };
    fetchData();
  }, [
    selectedOutlet,
    status,
    startDate,
    endDate,
    refresh,
    endDateFilename,
    mdr
  ]);

  React.useEffect(() => {
    setTabData((prevTabData) => ({
      ...prevTabData,
      filename: `riwayat-komisi_${startDate}-${endDateFilename}`
    }));
  }, [startDate, endDateFilename]);

  const customStyles = {
    headRow: {
      style: {
        minHeight: "78px"
      }
    },
    rows: {
      style: {
        minHeight: "70px"
      }
    }
  };

  const columns = React.useMemo(
    () => {
      const baseColumns = [
        {
          name: "No",
          selector: (row) => row.no,
          sortable: true,
          width: "70px"
        },
        {
          name: "Name",
          selector: (row) => row.name,
          sortable: true
        },
        {
          name: "ID",
          selector: (row) => row.id,
          sortable: true
        },
        {
          name: "Outlet Name",
          selector: (row) => row.outlet_name,
          sortable: true
        },
        {
          name: "Total Komisi",
          selector: (row) => row.total_komisi,
          sortable: true
        }
      ];
  
      if (isAnyRowExpanded) {
        baseColumns.push({
          name: "Export",
          cell: (row) => (
            expandedRows[row.id] ? (
              <ExportExcel
                className="btn btn-outline-primary btn-sm"
                table={`table-commission-${row.id}`}
                filename={`Commission_Report_${row.name}_${startDate}_${endDate}`}
                sheet="Commission Report"
                buttonText="Export"
              />
            ) : null
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true
        });
      }
  
      return baseColumns;
    },
    [expandedRows, startDate, endDate, isAnyRowExpanded]
  );

  const getCommissionData = async (id, status, start_range, end_range) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const businessId = JSON.parse(localStorage.getItem("user_info"))
      .business_id;
    const outlet_id = id ? `&outlet_id=${id}` : "";

    // const filterCommission = `?business_id=360&outlet_id=433&date_start=2024-01-01&date_end=2024-12-31`;

    if (start_range === end_range) {
      end_range = dayjs(end_range)
        .add(1, "day")
        .format("YYYY-MM-DD");
    }

    if (new Date(start_range) > new Date(end_range)) {
      start_range = dayjs(start_range)
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      end_range = dayjs(end_range)
        .add(1, "day")
        .format("YYYY-MM-DD");
    }

    try {
      const { data } = await axios.get(
        `${API_URL}api/v1/transaction-test/report-komisi-summary?business_id=${businessId}${outlet_id}&date_start=${start_range}&date_end=${end_range}`
      );
      setCommission(data.data);
    } catch (err) {
      setCommission([]);
    }
  };

  const dataCommission = commission.map((item, index) => {
    return {
      id: item.id,
      no: index + 1,
      name: item.name || "N/A",
      total_komisi: item.total_komisi || 0,
      outlet_name: item.Outlet && item.Outlet.name ? item.Outlet.name : "N/A",
      servicer_user_id: item.User && item.User.id ? item.User.id : "Unknown" // Check for null
    };
  });


  const fetchExpandedData = React.useCallback(
    async (rowData) => {
      if (expandedData[rowData.id]) {
        return expandedData[rowData.id];
      }

      try {
        const API_URL = process.env.REACT_APP_API_URL;
        const response = await axios.get(
          `${API_URL}api/v1/transaction-test/report-komisi-detail`,
          {
            params: {
              business_id: JSON.parse(localStorage.getItem("user_info"))
                .business_id,
              outlet_id: rowData.outlet_id,
              date_start: startDate,
              date_end: endDate,
              servicer_user_id: rowData.servicer_user_id,
              page: 1,
              per_page: 99999
            }
          }
        );

        setExpandedData((prevState) => ({
          ...prevState,
          [rowData.id]: response.data.data
        }));

        return response.data.data;
      } catch (error) {
        console.error("Error fetching expanded data:", error);
        return null;
      }
    },
    [startDate, endDate]
  );

  const handleRowExpanded = React.useCallback(
    (expanded, row) => {
      setExpandedRows((prevState) => {
        const newExpandedRows = {
          ...prevState,
          [row.id]: expanded
        };
        
        // Check if any row is expanded
        const anyRowExpanded = Object.values(newExpandedRows).some(value => value);
        setIsAnyRowExpanded(anyRowExpanded);
        
        return newExpandedRows;
      });
      if (expanded && !expandedData[row.id]) {
        fetchExpandedData(row);
      }
    },
    [fetchExpandedData]
  );

  const ExpandableRowComponent = React.useCallback(
    ({ data }) => {
      const rowData = expandedData[data.id];

      if (!rowData) return <LoadingIndicator />;
      if (rowData.length === 0) return <div>No additional data available</div>;

      // ... render the expanded row content using rowData
      const head = [
        "Transaction ID",
        "Receipt ID",
        "Product Name",
        "Price Product",
        "Komisi",
        "Date"
      ];

      const body = rowData.map((item) => [
        item.transaction_id || "N/A",
        item.Transaction && item.Transaction.receipt_id
          ? item.Transaction.receipt_id
          : "N/A",
        item.Product && item.Product.name ? item.Product.name : "N/A",
        item.price_product || 0,
        item.service_komisi || 0,
        dayjs(item.createdAt).format("DD-MM-YYYY HH:mm:ss")
      ]);

      return (
        <>
          <ListGroup style={{ padding: "1rem", marginLeft: "1rem" }}>
            <ListGroup.Item>
              <Row>
                {head.map((item, index) => (
                  <Col key={index} style={{ fontWeight: "700" }}>
                    {item}
                  </Col>
                ))}
              </Row>
            </ListGroup.Item>
            {body.map((item, index) => (
              <ListGroup.Item key={index}>
                <Row>
                  {item.map((val, valIndex) => (
                    <Col key={valIndex}>{val}</Col>
                  ))}
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>

          {/* Render the ExportCommissionTable component for the expanded data */}
          <ExportCommissionTable
            data={rowData}
            startDate={startDate}
            endDate={endDate}
            identifier={`table-commission-${data.id}`}
          />
        </>
      );
    },
    [expandedData]
  );

  return (
    <>
      <Row>
        <Col>
          <Paper elevation={2} style={{ padding: "1rem", height: "100%" }}>
            <FeatureReport
              handleStartDate={handleStartDate}
              handleEndDate={handleEndDate}
              tabData={tabData}
              handleMdr={handleMdr}
              handleEndDateFilename={handleEndDateFilename}
              handleSelectedOutlet={handleSelectedOutlet}
              titleReport="reportCommissionStaff"
              handleSelectStatus={handleSelectStatus}
              handleTimeStart={handleTimeStart}
              handleTimeEnd={handleTimeEnd}
              stateShowMdr={false}
            />

            <div style={{ display: "none" }}>
              {/* <div> */}
              <table id="table-history-commission">
                <thead>
                  <tr>
                    <th>{t("commissionReport")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                </tbody>

                <thead>
                  <tr>
                    <th>{t("date")}</th>
                    <td>
                      {startDate} - {endDateFilename}
                    </td>
                  </tr>
                  <tr>
                    <th>{t("statusTransaksi")}</th>
                    <td>{status ? status : "Semua Transaksi"}</td>
                  </tr>
                  <tr>
                    <th>{t("Staff")}</th>
                    <td>{t("allStaff")}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr></tr>
                </tbody>

                <thead>
                  <tr>
                    <th>{t("date&Time")}</th>
                    <th>Transaction ID</th>
                    <th>Receipt ID</th>
                    <th>Servicer Name</th>
                    <th>Outlet Name</th>
                    <th>Product Name</th>
                    <th>Service Komisi</th>
                    <th>Product Price</th>
                    <th>Notes</th>
                  </tr>
                </thead>

                <tbody>
                  <tbody>
                    {reports.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {dayjs(item.createdAt).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </td>
                          <td>{item.id || "N/A"}</td>
                          <td>
                            {item.Transaction && item.Transaction.receipt_id
                              ? item.Transaction.receipt_id
                              : "N/A"}
                          </td>
                          <td>
                            {item.User &&
                            item.User.User_Profile &&
                            item.User.User_Profile.name
                              ? item.User.User_Profile.name
                              : "Unknown"}
                          </td>
                          <td>
                            {item.Transaction &&
                            item.Transaction.Outlet &&
                            item.Transaction.Outlet.name
                              ? item.Transaction.Outlet.name
                              : "N/A"}
                          </td>
                          <td>
                            {item.Product && item.Product.name
                              ? item.Product.name
                              : "N/A"}
                          </td>
                          <td>{item.service_komisi || 0}</td>
                          <td>{item.price_product || 0}</td>
                          <td>{item.notes || "N/A"}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </tbody>
              </table>
            </div>

            <DataTable
              noHeader
              pagination
              columns={columns}
              data={dataCommission}
              style={{ minHeight: "100%" }}
              noDataComponent={t("thereAreNoRecordsToDisplay")}
              customStyles={customStyles}
              progressPending={loading}
              progressComponent={<LoadingIndicator />}
              expandableRows
              // filter expandable rows component based on startDate and endDate
              expandableRowsComponent={ExpandableRowComponent}
              onRowExpandToggled={handleRowExpanded}
              expandableRowExpanded={(row) => !!expandedRows[row.id]}
            />
          </Paper>
        </Col>
      </Row>
    </>
  );
};
