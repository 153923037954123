import React from "react";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import {
  Row,
  Col,
  InputGroup,
  Form
} from "react-bootstrap";
import { Paper } from "@material-ui/core";

import DataTable from "react-data-table-component";
import { Search} from "@material-ui/icons";

import useDebounce from "../../../hooks/useDebounce";

import "../../style.css";

const ProductCategoryTab = ({ refresh, handleRefresh, allCategories }) => {
  const { t } = useTranslation();
  const [multiSelect, setMultiSelect] = React.useState(false);
  const [clearRows, setClearRows] = React.useState(true);
  const [selectedData, setSelectedData] = React.useState([]);

  const [search, setSearch] = React.useState("");

  const debouncedSearch = useDebounce(search, 1000);

  const [filteredCategories, setFilteredCategories] = React.useState([]);

  const handleSelected = (state) => setSelectedData(state.selectedRows);

  React.useEffect(() => {
    const filtered = allCategories.filter((category) =>
      category.name.toLowerCase().includes(debouncedSearch.toLowerCase())
    );
    setFilteredCategories(filtered);
  }, [allCategories, debouncedSearch]);


  const categoryData = (data) => {
    if (!data || !data.length) {
      return [];
    }

    return data.map((item, index) => ({
      id: item.id,
      no: index + 1,
      name: item.name,
      products: item.Products ? item.Products.length : 0,
    }));
  };

  const customStyles = {
    headRow: {
      style: {
        minHeight: "78px"
      }
    },
    rows: {
      style: {
        minHeight: "70px"
      }
    }
  };

  const columns = [
    {
      name: `${t("no")}`,
      selector: "no",
      sortable: true,
      width: "70px"
    },
    {
      name: `${t("categoryName")}`,
      selector: "name",
      sortable: true
    },
    {
      name: `${t("numberOfProduct")}`,
      selector: "products",
      sortable: true
    }
  ];

  const handleSearch = (e) => setSearch(e.target.value);

  return (
    <Row>
      <Col md={12} style={{ minHeight: "100%" }}>
        <Paper elevation={2} style={{ padding: "1rem", height: "100%" }}>
          <div className="headerPage">
            <div className="headerStart">
              {!selectedData.length ? (
                <h3>{t("categoryTitle")}</h3>
              ) : (
                <h3>
                  {selectedData.length}
                  {t("itemSelected")}
                </h3>
              )}
            </div>
          </div>

          <div className="filterSection lineBottom">
            <InputGroup className="pb-3">
              <InputGroup.Prepend>
                <InputGroup.Text style={{ background: "transparent" }}>
                  <Search />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                placeholder="Search..."
                value={search}
                onChange={handleSearch}
              />
            </InputGroup>
          </div>

          <DataTable
            noHeader
            pagination
            columns={columns}
            data={categoryData(filteredCategories)}
            style={{ minHeight: "100%" }}
            selectableRows={multiSelect}
            onSelectedRowsChange={handleSelected}
            clearSelectedRows={clearRows}
            noDataComponent={t("thereAreNoRecordsToDisplay")}
            customStyles={customStyles}
          />
        </Paper>
      </Col>
    </Row>
  );
};

export default ProductCategoryTab;
