import React from "react";
import axios from "axios";
import objectPath from "object-path";
import { useTranslation } from "react-i18next";
import { useHtmlClassService } from "../../../layout";

import {
  Row,
  Col,
  Form,
} from "react-bootstrap";

export const OutletMenuDropdown = ({
  className,
  selectedOutlet,
  setSelectedOutlet,
}) => {
  const { t } = useTranslation();
  const [allOutlets, setAllOutlets] = React.useState([]);

  const getOutlets = async () => {
    const API_URL = process.env.REACT_APP_API_URL;

    try {
      const { data } = await axios.get(`${API_URL}/api/v1/outlet`);
      setAllOutlets(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    getOutlets();
  }, []);

  const handleSelectOutlet = (e) => {
    const { value } = e.target;
    let outlet;
    if (value) {
      outlet = allOutlets.find((item) => item.id === parseInt(value));
    }
    
    setSelectedOutlet({
      id: value,
      name: outlet ? outlet.Location?.name : "All Outlet"
    });
  };

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body d-flex flex-column">
        <div>
          <Form.Group as={Row} style={{ margin: "0" }}>
            <Form.Label
              style={{ alignSelf: "center", marginBottom: "0" }}
            >
              {t("outlet")}
            </Form.Label>
            <Col style={{ paddingRight: "0" }}>
              <Form.Control
                as="select"
                name="outlet_id"
                value={selectedOutlet.id}
                onChange={handleSelectOutlet}
              >
                <option value="">{t("all")}</option>
                {allOutlets.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </Form.Control>
            </Col>
          </Form.Group>
        </div>
      </div>
    </div>
  );
};
