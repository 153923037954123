import React from "react";
import { Button, Modal, Form, Spinner, Alert } from "react-bootstrap";

const ProductTopupForm = ({
  state,
  closeModal,
  loading,
  alert,
  setAlert = () => {},
  title,
  formikAddTopup,
  inputRef,
  t,
  hiddenCategory,
  handleHiddenCategory,
  isSubmitting // Add this prop
}) => {
  return (
    <Modal show={state} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formikAddTopup.handleSubmit}>
        <Modal.Body>
          {alert ? <Alert variant={alert.type}>{alert.message}</Alert> : null}

          <Form.Group>
            <Form.Label>{t("uploadImage")}</Form.Label>
            <Form.Control
              name="image"
              type="file"
              accept="image/*"
              onChange={(event) => {
                formikAddTopup.setFieldValue(
                  "image",
                  event.currentTarget.files[0]
                );
              }}
              onBlur={formikAddTopup.handleBlur}
              required
            />
            {formikAddTopup.touched.image && formikAddTopup.errors.image && (
              <p className="text-danger">{formikAddTopup.errors.image}</p>
            )}

            {formikAddTopup.values.image && (
              <img
                src={URL.createObjectURL(formikAddTopup.values.image)}
                alt="Preview"
                style={{ width: "100px", marginTop: "10px" }}
              />
            )}
          </Form.Group>

          <Form.Group>
            <Form.Label>{t("amount")}</Form.Label>
            <Form.Control
              type="number"
              placeholder="Ex. : 1000"
              {...formikAddTopup.getFieldProps("amount")}
              // value={formikAddTopup.values.amount}
              required
            />
            {formikAddTopup.touched.amount && formikAddTopup.errors.amount && (
              <p className="text-danger">{formikAddTopup.errors.amount}</p>
            )}
          </Form.Group>

          <Form.Group>
            <Form.Label>{t("description")}</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ex. : Top Up Description"
              {...formikAddTopup.getFieldProps("top_up_description")}
              // value={formikAddTopup.values.top_up_description}
              required
            />
            {formikAddTopup.touched.top_up_description &&
              formikAddTopup.errors.top_up_description && (
                <p className="text-danger">
                  {formikAddTopup.errors.top_up_description}
                </p>
              )}
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={closeModal}
            disabled={isSubmitting}
          >
            {t("close")}
          </Button>
          <Button type="submit" disabled={loading || isSubmitting}>
            {loading || isSubmitting ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              `${t("saveChanges")}`
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ProductTopupForm;
