import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls";
import { Form, InputGroup, Row, Col, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Search } from "@material-ui/icons";
import ExportExcel from "react-html-table-to-excel";
import Swal from "sweetalert2";
import Select from 'react-select';

export const CashlezTransactionReport = () => {
  const [reportData, setReportData] = useState([]);
  const [dateStart, setDateStart] = useState(getFormattedDate(new Date(new Date().setDate(new Date().getDate() - 30))));
  const [dateEnd, setDateEnd] = useState(getFormattedDate(new Date()));
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [outlets, setOutlets] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [userBusinessId, setUserBusinessId] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const authToken = localStorage.getItem('auth_token'); // Assuming you store the auth token in localStorage
  const [totalSummary, setTotalSummary] = useState({
    totalTransactions: 0,
    totalAmount: 0,
    totalFeeMerchant: 0,
    totalTaxPayment: 0
  });

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('user_info'));
    if (userInfo && userInfo.business_id) {
      setUserBusinessId(userInfo.business_id);
      console.log("Business ID set:", userInfo.business_id);
      fetchOutlets(userInfo.business_id);
    } else {
      console.log("No business ID found in localStorage");
    }
  }, []);

  const columns = [
    {
      name: "Tanggal",
      selector: row => row.date,
      sortable: true,
    },
    {
      name: "Total Transaksi",
      selector: row => row.totalTransactions,
      sortable: true,
      right: true,
    },
    {
      name: "Total Amount",
      selector: row => row.totalAmount,
      sortable: true,
      right: true,
      format: (row) => formatCurrency(row.totalAmount),
    },
    {
      name: "Total Fee Merchant",
      selector: row => row.totalFeeMerchant,
      sortable: true,
      right: true,
      format: (row) => formatCurrency(row.totalFeeMerchant),
    },
    {
      name: "Total Tax Payment",
      selector: row => row.totalTaxPayment,
      sortable: true,
      right: true,
      format: (row) => formatCurrency(row.totalTaxPayment),
    },
  ];

  const fetchReportData = useCallback(async (page, rowsPerPage, search) => {
    setIsLoading(true);
    
    if (!userBusinessId || !dateStart || !dateEnd) {
      setTransactions([]);
      setTotalRows(0);
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/cz-transaction`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          },
          params: {
            per_page: rowsPerPage,
            page: page,
            search: search,
            business_id: userBusinessId,
            outlet_id: selectedOutlet?.value || "",
            dateStart: dateStart,
            dateEnd: dateEnd
          }
        }
      );

      console.log("Response:", response.data);
      
      if (response.data && response.data.data && response.data.data.length > 0) {
        const startDate = new Date(dateStart);
        const endDate = new Date(dateEnd);
        endDate.setHours(23, 59, 59, 999); // Set end date to end of day

        const filteredData = response.data.data.filter(item => {
          const itemDate = new Date(item.created_date);
          const matchesSearch = search
            ? item.merchant_txid.toLowerCase().includes(search.toLowerCase())
            : true;
          return (
            item.business_id === userBusinessId &&
            (selectedOutlet?.value ? item.outlet_id === selectedOutlet.value : true) &&
            itemDate >= startDate && itemDate <= endDate &&
            matchesSearch
          );
        });
        
        console.log(`Filtered data: ${filteredData.length} items`);
        setTransactions(filteredData);
        setTotalRows(filteredData.length);
        
        // Process the filtered data for the report
        const processedData = processReportData(filteredData);
        setReportData(processedData);

        // Calculate total summary
        const summary = filteredData.reduce((acc, item) => {
          acc.totalTransactions += 1;
          acc.totalAmount += parseFloat(item.transaction_amount) || 0;
          acc.totalFeeMerchant += parseFloat(item.fee_merchant) || 0;
          acc.totalTaxPayment += parseFloat(item.tax_payment) || 0;
          return acc;
        }, {
          totalTransactions: 0,
          totalAmount: 0,
          totalFeeMerchant: 0,
          totalTaxPayment: 0
        });

        setTotalSummary(summary);
      } else {
        setTransactions([]);
        setReportData([]);
        setTotalRows(0);
        setTotalSummary({
          totalTransactions: 0,
          totalAmount: 0,
          totalFeeMerchant: 0,
          totalTaxPayment: 0
        });
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setTransactions([]);
      setReportData([]);
      setTotalRows(0);
      Swal.fire({
        icon: "error",
        title: "Gagal mengambil data",
        text: "Terjadi kesalahan saat mengambil data transaksi. Silakan coba lagi."
      });
    } finally {
      setIsLoading(false);
    }
  }, [userBusinessId, selectedOutlet, dateStart, dateEnd, authToken]);

  const processReportData = (data) => {
    const reportMap = new Map();

    data.forEach(transaction => {
      const date = transaction.created_date.split('T')[0];
      
      if (!reportMap.has(date)) {
        reportMap.set(date, {
          date,
          totalTransactions: 0,
          totalAmount: 0,
          totalFeeMerchant: 0,
          totalTaxPayment: 0
        });
      }

      const report = reportMap.get(date);
      report.totalTransactions += 1;
      report.totalAmount += parseFloat(transaction.transaction_amount) || 0;
      report.totalFeeMerchant += parseFloat(transaction.fee_merchant) || 0;
      report.totalTaxPayment += parseFloat(transaction.tax_payment) || 0;
    });

    return Array.from(reportMap.values());
  };

  useEffect(() => {
    if (userBusinessId) {
      fetchReportData(currentPage, perPage, searchTerm);
    }
  }, [fetchReportData, userBusinessId, currentPage, perPage, searchTerm]);

   const fetchOutlets = async (businessId) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/business/${businessId}`);
      const outletOptions = data.data.Outlets.map(outlet => ({
        value: outlet.id,
        label: outlet.name
      }));
      setOutlets([{ value: "", label: "Semua Outlet" }, ...outletOptions]);
    } catch (error) {
      console.error("Error fetching outlets:", error);
    }
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === "dateStart") {
      setDateStart(value);
    } else if (name === "dateEnd") {
      setDateEnd(value);
    }
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleOutletChange = (selectedOption) => {
    console.log("Selected outlet:", selectedOption);
    setSelectedOutlet(selectedOption);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR"
    }).format(amount);
  };

  function getFormattedDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const customStyles = {
    rows: {
      style: {
        minHeight: '72px',
      }
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
    },
  };

  return (
    <Card>
      <CardHeader title="Laporan Transaksi Cashlez">
        <div className="card-toolbar">
          <ExportExcel
            id="test-table-xls-button"
            className="btn btn-success font-weight-bolder font-size-sm"
            table="table-to-xls"
            filename="cashlez_transaction_report"
            sheet="report"
            buttonText="Export to Excel"
          />
        </div>
      </CardHeader>
      <CardBody>
        <div className="form-group row mb-6">
          <div className="col-lg-3 mb-lg-0 mb-6">
            <Form.Group>
              <Form.Label>Outlet:</Form.Label>
              <Select
                value={selectedOutlet}
                onChange={handleOutletChange}
                options={outlets}
                isClearable
                isSearchable
                placeholder="Pilih Outlet"
              />
            </Form.Group>
          </div>
          <div className="col-lg-3 mb-lg-0 mb-6">
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <Search />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                placeholder="Cari transaksi"
                value={searchTerm}
                onChange={handleSearch}
              />
            </InputGroup>
          </div>
          <div className="col-lg-3 mb-lg-0 mb-6">
            <Form.Group>
              <Form.Label>Tanggal Mulai:</Form.Label>
              <Form.Control
                type="date"
                name="dateStart"
                value={dateStart}
                onChange={handleDateChange}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3 mb-lg-0 mb-6">
            <Form.Group>
              <Form.Label>Tanggal Akhir:</Form.Label>
              <Form.Control
                type="date"
                name="dateEnd"
                value={dateEnd}
                onChange={handleDateChange}
              />
            </Form.Group>
          </div>
        </div>

        <DataTable
          columns={columns}
          data={reportData}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          progressPending={isLoading}
          progressComponent={<div className="d-flex justify-content-center my-5">
            <div className="spinner spinner-track spinner-primary mr-10"></div>
            <span>Memuat data laporan...</span>
          </div>}
          noDataComponent={<div className="text-center my-5">Tidak ada data yang tersedia</div>}
          customStyles={customStyles}
        />

        <div className="mt-5">
          <table className="table table-bordered" style={{ tableLayout: 'fixed', width: '100%' }}>
            <thead>
              <tr className="bg-light-primary">
                <th style={{ width: '20%' }}>Tanggal</th>
                <th style={{ width: '20%', textAlign: 'right' }}>Total Transaksi</th>
                <th style={{ width: '20%', textAlign: 'right' }}>Total Amount</th>
                <th style={{ width: '20%', textAlign: 'right' }}>Total Fee Merchant</th>
                <th style={{ width: '20%', textAlign: 'right' }}>Total Tax Payment</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{`${dateStart} - ${dateEnd}`}</td>
                <td className="text-right">{totalSummary.totalTransactions}</td>
                <td className="text-right">{formatCurrency(totalSummary.totalAmount)}</td>
                <td className="text-right">{formatCurrency(totalSummary.totalFeeMerchant)}</td>
                <td className="text-right">{formatCurrency(totalSummary.totalTaxPayment)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
};

export default CashlezTransactionReport;